@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.animate-fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 1.3s;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slideInRight {
  animation: slideInFromRight 1.5s cubic-bezier(0.68, -0.55, 0.27, 1) forwards;
}
