.svg-border rect {
  animation: draw 2.1s linear forwards, fadeOut 0.7s linear 2s forwards;
}

.svg-border-fadein {
  animation: fadeIn 1s linear 3s forwards, fadeBorder 1s linear 4s forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
